import { useUserApi } from '~/api/user'
import { useAppStore } from '~/store/app'
import { useValidate } from '~/composables/useValidate'
import { MODAL_TYPES } from '~/config/constant'
import { useNuxtApp, useRoute, navigateTo } from '#app'

interface RegisterForm {
  username: string;
  password: string;
  phone: string;
}

export const useRegister = () => {
  const { REGISTER, VERIFY_USERNAME } = useUserApi()
  const { $axios, $alertMsg, $bvModal, $pinia, $device } = useNuxtApp()
  const { validateRefs } = useValidate()
  const { setCurrentUser } = useAppStore($pinia)

  const isLoading = ref<boolean>(false)
  const route = useRoute()
  const isNameExists = ref<boolean>(false)
  const username = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const password = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const phone = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const isAgree = ref<boolean>(true)

  const formRegister: RegisterForm = reactive({
    username: '',
    password: '',
    phone: ''
  })

  const submit = async (): Promise<void> => {
    isLoading.value = true

    const validateList: string | any[] = reactive([username, password, phone])
    const isValid: boolean = validateRefs(validateList)

    if (isValid) {
      isNameExists.value = await verifyUsername(formRegister.username.toLowerCase())
    }

    if (isValid && !isNameExists.value) {
      if ($device.isMobileOrTablet) {
        window.scrollTo({
          top: -100,
          behavior: 'smooth'
        })
      }
      await submitRegister(formRegister)
    }

    isLoading.value = false
  }

  const submitRegister = async (formRegister: RegisterForm): Promise<void> => {
    const phone: string = formRegister.phone?.replace(/\D/g, '') || ''
    const body: RegisterForm = { ...formRegister, phone }

    try {
      const { data: response } = await $axios.post(REGISTER, body, { withCredentials: true })

      if (response && response.status === 'OK') {
        window.dataLayer = window.dataLayer || []
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'formSubmitted',
            formName: 'Form_Register'
          })
        }
        const { 0: data } = response.data
        setCurrentUser(data)
        $bvModal.hide(MODAL_TYPES.LOGIN)

        if (route.query?.navigateUrl) {
          navigateTo(route.query.navigateUrl as string)
        }
      } else {
        $alertMsg('warning', response.message)
      }
    } catch (error: any) {
      console.log('error', error)
    }
  }

  const verifyUsername = async (username: string): Promise<boolean> => {
    try {
      const { data: response } = await $axios.post(VERIFY_USERNAME, { username }, { withCredentials: true })
      return response.exist
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return { isAgree, isNameExists, username, password, phone, formRegister, isLoading, submit }
}
